import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/design/design/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`This guide will show you how to upgrade from the now deprecated
`}<inlineCode parentName="p">{`Primer::LocalTime`}</inlineCode>{` component to the latest `}<a parentName="p" {...{
        "href": "/components/relative-time/rails"
      }}><inlineCode parentName="a">{`Primer::Beta::RelativeTime`}</inlineCode></a>{`
component.`}</p>
    <h2>{`A Migration Example`}</h2>
    <p>{`The most common use case of the `}<inlineCode parentName="p">{`LocalTime`}</inlineCode>{` component can be migrated with only
a few minor changes.`}</p>
    <p>{`For example, if the `}<inlineCode parentName="p">{`LocalTime`}</inlineCode>{` was set up in this way:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-erb"
      }}>{`<%= Primer::LocalTime(datetime: Time.now, initial_text: Time.now.iso8601) %>
`}</code></pre>
    <p>{`It can be migrated by removing `}<inlineCode parentName="p">{`initial_text`}</inlineCode>{`, setting an empty `}<inlineCode parentName="p">{`prefix`}</inlineCode>{`, and
adding `}<inlineCode parentName="p">{`threshold: "PT0S"`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-erb"
      }}>{`<%= Primer::Beta::RelativeTime(datetime: Time.now, prefix: "", threshold: "PT0S") %>
`}</code></pre>
    <p>{`The `}<inlineCode parentName="p">{`RelativeTime`}</inlineCode>{` component defaults to the `}<inlineCode parentName="p">{`iso8601`}</inlineCode>{` format and does not need
to be specified directly.`}</p>
    <p>{`The `}<inlineCode parentName="p">{`threshold`}</inlineCode>{` value is an `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/ISO_8601#Durations"
      }}>{`ISO-8601 "duration"`}</a>{` that tells the `}<inlineCode parentName="p">{`RelativeTime`}</inlineCode>{`
component to display the absolute date/time, instead of relative time
description. The example of `}<inlineCode parentName="p">{`PT0S`}</inlineCode>{` says to switch to absolute time display
starting zero (0) seconds ago. In practice, this means it will always display
the absolute time. With the `}<inlineCode parentName="p">{`LocalTime`}</inlineCode>{` component, `}<inlineCode parentName="p">{`PT0S`}</inlineCode>{` was the default
threshold. The `}<inlineCode parentName="p">{`RelativeTime`}</inlineCode>{` component defaults to `}<inlineCode parentName="p">{`P30D`}</inlineCode>{`, however, and
it will need to be zeroed out to always display a datetime.`}</p>
    <h2>{`Arguments`}</h2>
    <p>{`The following arguments are different between `}<inlineCode parentName="p">{`LocalTime`}</inlineCode>{` and `}<inlineCode parentName="p">{`RelativeTime`}</inlineCode>{`.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`From `}<inlineCode parentName="th">{`Primer::LocalTime`}</inlineCode></th>
          <th parentName="tr" {...{
            "align": null
          }}>{`To `}<inlineCode parentName="th">{`Primer::Beta::RelativeTime`}</inlineCode></th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Notes`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`initial_text`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`n/a`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No longer used.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`n/a`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`tense`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Which tense to use. One of `}<inlineCode parentName="td">{`:auto`}</inlineCode>{`, `}<inlineCode parentName="td">{`:future`}</inlineCode>{`, or `}<inlineCode parentName="td">{`:past`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`n/a`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`prefix`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`What to prefix the relative ime display with.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`n/a`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`threshold`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The threshold, in ISO-8601 'durations' format, at which relative time displays become absolute.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`n/a`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`precision`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The precision elapsed time should display. One of nil, `}<inlineCode parentName="td">{`:day`}</inlineCode>{`, `}<inlineCode parentName="td">{`:hour`}</inlineCode>{`, `}<inlineCode parentName="td">{`:minute`}</inlineCode>{`, `}<inlineCode parentName="td">{`:month`}</inlineCode>{`, `}<inlineCode parentName="td">{`:second`}</inlineCode>{`, or `}<inlineCode parentName="td">{`:year`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`n/a`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`format`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The format the display should take. One of `}<inlineCode parentName="td">{`:auto`}</inlineCode>{`, `}<inlineCode parentName="td">{`:elapsed`}</inlineCode>{`, or `}<inlineCode parentName="td">{`:micro`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`n/a`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`lang`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The language to use.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`n/a`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`title`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Provide a custom title to the element.`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`The remaining arguments stayed the same.`}</p>
    <p>{`Please see the following documentation for complete descriptions and examples.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/relative-time/rails"
        }}><inlineCode parentName="a">{`Primer::Beta::RelativeTime`}</inlineCode>{` component`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://primer.style/view-components/lookbook/inspect/primer/beta/relativetime/default"
        }}><inlineCode parentName="a">{`Primer::Beta::RelativeTime`}</inlineCode>{` Lookbook examples`}</a></li>
    </ul>
    <p>&nbsp;</p>
    <p><a parentName="p" {...{
        "href": "/guides/development/rails#upgrading-deprecated-components"
      }}>{`←`}{` Back to development docs`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      